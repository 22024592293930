/*eslint-disable*/
import React from "react";
import Link from "next/link";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import ListItem from "@material-ui/core/ListItem";
import Button from "/components/CustomButtons/Button.js";

import styles from "/styles/jss/bbhost/pages/componentsSections/navbarsStyle.js";

const useStyles = makeStyles(styles);

import { trans } from "lang/lang.js";
import classNames from "classnames";

export default function ActionNav({name, text, route, icon, color}) {
  const classes = useStyles();  
  
  return (
    <ListItem className={classes.listItem}>
        <Link href={route} as={route}>
          <Button
            href={route}
            color={color}
            className={classNames(classes.navLink, "open_painel_cliente")}
          >
            <FontAwesomeIcon  className={classes.socialIcons} icon={icon} size="lg" /> {trans(text)}
          </Button>
        </Link>    
    </ListItem>
  );
}
